.btn {
    color: black;
    background-color: #d3d3d3;
    border: 2px solid black;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    font-size: 3vw;
  }
  
.btn:hover {
  color: black;
}

#hidden {
  visibility: hidden;
}

#menuButtonHide {
  display: none;
}

.menuButton {
  background-color: #898989;
  color: black;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 3.2vw;
  height: 6vh;
  border: var(--footer-header-buttons);
}

.menuButton:hover {
  color: white;
  background-color: black;
  transition: 0.5s;
}

.menuButton:hover {
  opacity: 100%;
}

.white {
  color: black;
  background-color: #898989;
  border: var(--footer-header-buttons);
  border-radius: var( --button-radius-small);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 3.2vw;
  width: 25vw;
  min-height: 6vh;
  height: 6vw;
  transition: 0.5s;
  text-transform: uppercase;
  font-weight: 800;

}

.white:hover {
color: white;
background-color: black;
transition: 0.5s;
}

.whiteMobile {
  color: black;
  background-color: #898989;
  border: var(--footer-header-buttons);
  border-radius: var( --button-radius-small);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 3.2vw;
  width: 70vw;
  min-height: 6vh;
  height: 6vw;
  transition: 0.5s;
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 5px;
  
}

.whiteMobile:hover {
color: white;
background-color: black;
transition: 0.5s;
}

.crazy {
  color: black;
  background-color: #d3d3d3;
  border: 2px solid black;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  font-weight: 800;
  width: 100%;
  transition: 0.9s;
}

.crazy:hover {
color: white;
background-color: red;
  background-image:
    linear-gradient(
      to right, 
      red, 
      #f06d06, 
      rgb(255, 255, 0), 
      green
    );
transition: 0.5s;
}

.light {
  color: black;
  background-color: #d3d3d3;
  border-radius: var(--long-button-radius);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.1rem;
  height: var(--tutor-button-height);
  font-weight: 800;
  width: 100%;
  min-height: 50px;
  transition: 0.9s;
}

.light:hover {
color: white;
background-color: black;
}

.footer {
  color: black;
  background-color: #898989;
  border: var(--footer-header-buttons);
  border-radius: var( --button-radius-small);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 6vw;
  font-weight: 800;
  width: 15%;
  height: 5vh;
  transition: 0.9s;
}

.footer:hover {
background-color: white;
}

.subContent {
  color: black;
  background-color: #898989;
  border: var(--footer-header-buttons);
  border-radius: var( --button-radius-small);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 3vw;
  padding: 10px;
  font-weight: 500;
  text-transform: uppercase;
  width: 35vw;
  height: auto;
  transition: 0.9s;
}

.subContent:hover {
background-color: white;
}

.subContentSingle {
  color: black;
  background-color: #898989;
  border: var(--footer-header-buttons);
  border-radius: var( --button-radius-small);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 3vw;
  padding: 10px;
  font-weight: 500;
  text-transform: uppercase;
  width: 60vw;
  height: auto;
  transition: 0.9s;
}

.subContentSingle:hover {
background-color: white;
}

button {
  display: flex;
  flex-direction: row;
  background-color: #d3d3d3;
  justify-content: center;
  font-size: var(--button-font-size);
  align-items: center;
  border: var(--sub-button-border);
  border-radius: var( --long-button-radius);
  width: auto;
  height: var(--tutor-button-height);
}

.footerLabel {
  display: none;
}

/*Screen Sizes*/

@media screen and (min-width: 768px) {
  .footer {
    width: 15%;
    height: 50%;
    font-size: 2vw;
  }

  .white {
    font-size: 25px;
  }

  .footerLabel {
    display: inline;
  }

  .light {
    font-size: var(--button-font-size);
  }

  .subContent {
    font-size: 2vw;
    width: 16vw;
  }

  .subContentSingle {
    font-size: 2vw;
    width: 35vw;
  }
}

@media screen and (min-width: 1024px) {
  .white {
    width: 32%;
    margin: 2%;
    height: 100%;
  }

  .light {
    width: 100%;
    height: 100%;
  }

  .crazy {
    font-size: 2.4vh;
  }

  .footer {
    margin-top: 1%;
    height: 70%;
  }

}


