
.container1 {
    background-color: red;
}

#mainContentContainer {
    /* background-color: brown; */
    display: flex;
    font-weight: 500;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    height: auto;
  }

/* Containers for main content and image*/
.border {
    width: 100%;
    /* background-color: #d3d3d3; */
    border-radius: 20px;
    margin: 1% 0 1% 0;
    font-size: 20px;
    overflow: auto;
    text-transform: uppercase;
}



.halfPageContainer1 {
    width: 100%;
    order: 2;
    height: 30vh;
    display: flex;
}

.halfPageContainer2 {
    width: 100%;
    order: 3;
    height: auto;
    display: flex;
}

/* Contact text information*/
#contactText {
    /* background-color: aliceblue; */
    text-align: justify;
    margin: 20px;
    height: 40vh;
}

#text {
    /* background-color: lightgrey; */
    width: 100%;
    height: 100%;
}

/* medium devices */
@media screen and (min-width: 768px) {

    /* Container for AutoHeaderText*/
    #AutoHeaderContainer {
        display: flex;
        width: 100%;
        order: 1;
        height: 20%;
        /* font-size: 2.7vw; */
      }

    /* Containers for main content and image*/
    .halfPageContainer1 {
        width: 100%;
        order: 2;
        height: 20%;
        display: flex;
    }

    .halfPageContainer2 {
        width: 100%;
        order: 3;
        height: 700px;
        display: flex;
    }
}

/* larger devices */
@media screen and (min-width: 1060px) {

    /* Container for AutoHeaderText*/
    #AutoHeaderContainer {
        display: flex;
        max-height: 20%;
        /* font-size: 1.8vw; */
      }

    /* Containers for main content and image*/
    .halfPageContainer1 {
        width: 50%;
        padding-right: 1%;
        height: 60vh;
        display: flex;
    }

    .halfPageContainer2 {
        width: 50%;
        padding-left: 1%;
        height: 60vh;
        display: flex;
    }

    p {
        background-color: black;
        color: white;
        border-radius: 6px;
        font-size: 2vw;
    }

}