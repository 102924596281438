
.cubeContainer {
  width: 100%;
  height: 100%;
  perspective: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  touch-action: none;
}

.cube {
  position: relative;
  width: 160px;
  height: 160px;
  transform-style: preserve-3d;
  transition: transform 0.3s ease-in-out;
  font-family: "Saira", sans-serif;
  font-weight: 400;
  font-size: 20px; /* FONT SIZE */
  background-color: rgba(255, 255, 255, 0.0);
}

.face {
  position: absolute;
  width: 160px;
  height: 160px;
  border: 2px solid black;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
}

.front {
  transform: translateZ(80px);

}

.back {
  transform: translateZ(-80px) rotateY(180deg);
  
}

.top {
  transform: rotateX(90deg) translateZ(80px);
 
}

.bottom {
  transform: rotateX(-90deg) translateZ(80px);
 
}

.left {
  transform: rotateY(-90deg) translateZ(80px);
  background-color: lightgreen;
}

.right {
  transform: rotateY(90deg) translateZ(80px);
  background-color: lightgreen;
  
}

/* Copied from cube game*/
.page {
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: palevioletred;
  font-size: 60px;
  color: black;
  
}

.clickedWords {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  font-family: "Rubik Mono One";
  font-size: 20px; 
  text-transform: uppercase;
}

/* Black text*/
.clickable {
  cursor: pointer;
  color: black;
}

.clicked {
  opacity: 0.2;
}

/* white text*/
.clickableWhite {
  cursor: pointer;
  color: white;
}

.clickedWhite {
  opacity: 0.2;
}

@media screen and (min-width: 1024px) {
  #mainContainer {
    padding: 0;

  }

  .clickedWords {
    font-size: xx-large;
  }
}
