.hideCaption {
  display: none;
}

#autoTextContainer {
  padding-top: 2%;
  padding-left: 2%;
  height: 200px;
  width: 100%;
  overflow: visible;
  align-self: self-start;
  border-radius: 0;
}

#headerTextContainer {
  overflow: visible;
  color: black;
  text-transform: uppercase;
}


#headerContainer {
  width: 100%;
  height: 20vh;
  padding: 1%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  border-radius: 25px;
  margin-bottom: 10px;
}

#mainContentContainer {
  display: flex;
  font-weight: 500;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: auto;
}

.img {
  width: 100%;
  height: 100%;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 0;
}

.thumbnailWrapper {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding-bottom: 2%;
}

.thumbnail {
  border-radius: 28px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnailWrapper {

  position: relative;
  overflow: hidden;

}

.thumbnailOverlay {
  border-radius: 28px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 98%;
  background-color: rgba(238, 230, 230, 0.926);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.thumbnailWrapper:hover .thumbnailOverlay {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.caption {
  background-color: transparent;
  width: 80%;
  height: 80%;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  margin: 5px;
  padding: 5px;
  color: black;
  border-radius: var(--image-border-radius);
}

.title {
  background-color: #898989;
  display: flex;
  align-items: center;
  position: absolute;
  border: solid black 2px;
  border-radius: var(--button-radius-small);
  top: 5%;
  left: 50%;
  text-transform: uppercase;
  transform: translateX(-50%);
  padding: 15px 10px 15px 10px;
  font-size: 15px;
  font-weight: 800;
  line-height: 1.2;
  text-align: left;
  width: 90%;
  height: auto;
}

.border {
  width: 100%;
  background-color: transparent;
  margin: 1% 0 1% 0;
  font-size: 20px;
  overflow: auto;
  height: auto;
}

#container {
  width: 100%;
  order: 2;
  height: auto;
  min-height: var(--half-page-container-min-height);
  display: flex;
  padding: var(--virticle-padding);
}

/* CONTENT FORMATTING*/
.highlightContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: centre;
  justify-content: space-around;
}

.highlightText {
  font-size: 4vw;
  text-align: justify;

}

.devIconContainer {
  width: 100%;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-evenly;
}

.tooltip {
  position: relative;
  display: inline-block;
  text-transform: capitalize;
}

.tooltip .tooltipText {
  visibility: hidden;
  position: absolute;
  bottom: 70%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  color: black;
  white-space: nowrap;
  font-size: 10px;
}

.tooltip:hover .tooltipText {
  visibility: visible;
}

.highlightButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-content: center;
}

@media screen and (min-width: 540px) {
  .title {
    font-size: 20px;
    padding-left: 13px;
  }

  .devIconContainer {
    font-size: 30px;
  }

  .tooltip .tooltipText {
    bottom: 80%;
    font-size: 12px;
  }
}


@media screen and (min-width: 768px) {

  .border {
    height: 100%;
  }

  .gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    font-size: 20px;
    padding-left: 15px;
  }

  .thumbnailWrapper {
    width: 49%;
    height: auto;
    overflow: hidden;
    margin-top: 10px;
    padding-bottom: 10px;
  }

  #autoTextContainer {
    width: 49%;
    overflow: hidden;
  }

  .thumbnailOverlay {
    border-radius: 31px;
    height: 97%;
  }

  .thumbnail {
    border-radius: 31px;
  }


  .highlightContainer {
    flex-wrap: nowrap;
  }

  .highlightText {
    font-size: 2vw;
  }

  .devIconContainer {
    font-size: 30px;
  }

  .tooltip .tooltipText {
    bottom: 70%;
    font-size: 10px;
  }

  #autoTextContainer {
    padding-top: 10px;
    padding-left: 5px;
    width: 49%;
    min-height: 12vw;
    height: 22vh;
    padding-bottom: 10px;
  }

}

@media screen and (min-width: 1024px) {

  #mainContentContainer {
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 610px;
    height: auto;
  }

  .thumbnailOverlay {
    border-radius: 60px;
  }

  .border {

    height: 100%;
  }

  .title {
    font-size: 2vw;
    border-radius: var(--button-radius-small);
    top: 10%;
    border: black solid 3px;
  }

  .thumbnailWrapper {
    width: 49%;
    height: auto;
    padding-bottom: 20px;
  }

  .thumbnail {
    border-radius: 60px;
    height: 60vh;
  }

  .highlightContainer {
    flex-wrap: wrap;
  }

  .highlightText {
    font-size: 2vw;
  }

  .thumbnailOverlay {
    height: 98%;
  }

  #autoTextContainer {
    padding-left: 20px;
    padding-top: 20px;
    width: 49%;
    min-height: 30vw;
    height: 22vh;
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 2400px) {
  #autoTextContainer {
    width: 49%;
    min-height: 12vw;
    height: 57vh;
    padding-bottom: 10px;
  }

  .highlightText {
    font-size: 3vh;
  }

  .devIconContainer {
    font-size: 35px;
  }

  .tooltip .tooltipText {
    bottom: 70%;
    font-size: 15px;
  }
}