#comingSoon {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
}

.aboutText {
    
    color: black;
    width: 80%;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 10px;
    font-size: smaller;
    text-align: justify;
    text-transform: uppercase;
    line-height: 1.5;
    letter-spacing: 2px;
}

.subAboutText {
    margin-top: 10px;
}


.dataContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px;
    width: 60%;
    max-width: 800px;
    padding: 20px;
    background-color: #898989;
    color: white;
    border-radius: 20px;
    font-size: smaller;
}

.inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    background-color: #898989;
    color: white;
    border-radius: 20px;
    font-size: smaller;
}

.liveData {
    border: 3px solid black;
    padding: 5px;
    border-radius: 15px;
    min-width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    color: white;
}