
#page {
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: column;

}

.infoReveal {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    height: 100px;
    text-align: center;
    font-size: 12px;
    
}

.container {

    width: 140px;
    height: 180px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 2;
}

.music {
    background-color: black;
    color: white;
    font-size: 12px;
    border-radius: 5px;
    height: auto;
    width: 100%;
    padding: 2px 0 2px 10px;
    z-index:3;
    border: none; 
}

.music:hover {
    background-color: blue;
}

.line {
    background-color: black;
    color: white;
    font-size: 12px;
    border-radius: 5px;
    height: auto;
    width: 100%;
    padding: 2px 0 2px 10px;
    z-index:3;
    border: none;
}

.line:hover {
    background-color: red;
}


.black {
    color: black;
}

@media screen and (min-width: 768px) {
    .container {
        width: 280px;
        height: 360px;
        
    }

    .line {
        font-size: 24px;
        border-radius: 10px;
        padding: 4px 0 4px 20px;
    }

    .infoReveal {
        font-size: 17px;
    }
}

@media screen and (min-width: 1080px) {
    .container {
        width: 280px;
        height: 360px;
        
    }

    .line {
        font-size: 24px;
        border-radius: 10px;
        padding: 4px 0 4px 20px;
    }

    .hovered {
        background-image: none;
      }
}