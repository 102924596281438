.border {
  width: 100%;
  background-color: white;
  border-radius: var(--medium-container-radius);
  padding: 8px;
  flex-direction: row;
  overflow: auto;
  display: flex;
  justify-content: space-around;
}


#thumb {
  width: 33%;
  padding: 10px;
  flex-shrink: 0;
}

img {
  border-radius: var(--image-border-radius);
  height: 100%;
  object-fit: contain;
}

.leftContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  height: 100%;
}

.helpButton {
  width: 100%;
  font-weight: 800;
  background-color: white;
  color: black;
  transition: 0.9s;
  height: 90%;
}

.helpButton:hover {
  background-color: black;
  color: white;
}

#text {

}

#left {
  display: flex;
  padding-top: 3px;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 40%;
  color: black;
}

.buttonContainer {
  height: 40%;
  flex-basis: auto;
  flex-grow: 1;
}

@media screen and (min-width: 768px) {

.left {
  width: 60%;
}

}

@media screen and (min-width: 1080px) {

  .border {
    margin: 0;
    padding: 22px;
  }

  .helpButton {
    min-height: 60px;
    height: 7vh;
  }

  img {
    border-radius: 10px;
    height: 100%;
    object-fit: contain;
  }

}