.app {
  display: flex;
  justify-content: center;
}

:root {
  --main-padding-top: 5%;
  --virticle-padding: 0 0 12px 0;

  --button-radius-small: 12px;
  --medium-container-radius: 15px;
  --border-radius-small: 20px;
  --image-border-radius: 10px;
  --long-button-radius: 10px;

  --sub-section-container-height: 20vw;
  --sub-section-container-min-height: 120px;


  --half-page-container-height: 45vh;
  --half-page-container-min-height: 90vw;

  --sub-text-more: .6em;
  --sub-text-less: 2em;
  --button-font-size: 1.6em;

  --learn-image-size: 5em;

  --tutor-button-height: 2em;

  --sub-button-border: solid black 2px;
  --sub-button-border: 3px solid black;
  --footer-header-buttons: 2px solid black;
  --headline-line-height: 1;
  --spinner-size: 80px;
  --spinner-thickness: 80px;
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  opacity: 50;
  animation: fade-in 1s ease-in-out forwards;
}

.spinner {
  width: var(--spinner-size);
  height: 80px;
  padding: 10px;
  margin: 10px;
  position: relative;
}

.spinner::before,
.spinner::after {
  content: "";
  font-size: 10px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: var(--spinner-thickness);
  border-radius: var(--button-radius-small);
  color: white;
  background-color: white;
  animation: spin 1s linear 0s 2 forwards;
  opacity: 0;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


body {

  font-family: 'Inter', sans-serif;
  font-weight: 800;
  background-color: #898989;
}

a {
  text-decoration: none;
}

/* main container */
#main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0% 2% 0 2%;
  padding-top: var(--main-padding-top);
}

/* MAIN SECTIONS OF WEBSITE*/
#header {
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--virticle-padding);
}

#main-content-container {
  display: flex;
  font-weight: 500;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: auto;
}

#footer {
  height: 15vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

#subcontent-container-top {
  order: 3;
  width: 100%;
  min-height: 139px;
  height: 9.5em;
  display: flex;
  padding: var(--virticle-padding);
}

#subcontent-container-bottom {
  order: 4;
  width: 100%;
  min-height: var(--sub-section-container-min-height);
  height: var(--sub-section-container-height);
  display: flex;
  padding: var(--virticle-padding);
}

#half-page-container {
  width: 100%;
  order: 2;
  height: var(--half-page-container-height);
  min-height: var(--half-page-container-min-height);
  display: flex;
  padding: var(--virticle-padding);
}

#half-page-container-size-to-content {
  width: 100%;
  order: 2;
  display: flex;
  padding: var(--virticle-padding);
}

/* EFFECTS*/
/* Effect Classes*/

.element {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.slide-left {
  animation-name: slide-in;
  animation-duration: 1s;
}

.slide-right {
  animation-name: slide-right;
  animation-duration: 1s;
}

.slide-down {
  animation-name: slide-down;
  animation-duration: 0.6s;
}

.slide-up {
  animation-name: slide-up;
  animation-duration: 0.6s;
}

.fade-in {
  opacity: 0;
  animation: fade-in 1s ease-in forwards;
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.fade-in-scale {
  animation: fadeInScale 1s ease-out;
}

@keyframes shake2 {
  0% {
    transform: translateX(0);
  }

  75% {
    transform: translateX(1px) rotate(0.5deg);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px) rotate(-5deg);
  }

  75% {
    transform: translateX(5px) rotate(5deg);
  }

  100% {
    transform: translateX(0);
  }
}

.zoom-in {
  animation: fadeIn 2s ease, zoomIn 1s ease;
  animation-fill-mode: both;
}

.shake {
  animation: shake 1s ease-in-out 2;
}

.shake2 {
  animation: shake2 0.5s ease-in-out 1;
}

/* Effect CSS */
@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide-right {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide-down {
  from {
    transform: translateY(-400%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slide-up {
  from {
    transform: translateY(400%);
  }

  to {
    transform: translateY(0);
  }
}

/*HEADER*/
/* Header content containers*/

.header-element-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/*LOGO*/
#logo-image {
  width: 13vw;
  min-width: 90px;
  max-width: 286px;
  border-radius: 0;
}

.header-element-right {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 100%;
  width: 80%;
}

/* MAIN CONTENT */
#header-text-container {
  text-transform: uppercase;
  letter-spacing: 0.1vw;
  text-align: justify;
  display: flex;
  width: 100%;
  order: 1;
  height: 156px;
  display: flex;
  flex-wrap: inherit;
  align-content: space-around;
  padding: var(--virticle-padding);
}

.text-border-buy {
  background-color: #d3d3d3;
  width: 100%;
  border-radius: var(--medium-container-radius);
  font-size: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 4%;
}

.contact-button div {
  padding: 5px;
}

.image-container-learn {
  height: 100%;
  width: auto;
  align-items: center;
  display: flex;
}

.image-container {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.contact-button {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 40%;
}

#buy-tagline {
  width: 100%;
  height: 8vh;
  max-height: 90px;
  margin: 7px;
  padding: 5px;
  text-align: justify;
  justify-content: center;
  align-content: end;
  flex-wrap: wrap;
  display: flex;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#main-pic {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* A Class that sits inside each main content section*/
.text-border {
  width: 100%;
  background-color: #d3d3d3;
  border-radius: var(--medium-container-radius);
  font-size: 20px;
  overflow: auto;
}

.gallery-text-border {
  width: 100%;
  background-color: #d3d3d3;
  border-radius: 20px;
  margin: 1% 0 1% 0;
  font-size: 20px;
  overflow: auto;
  height: 70vh;
}

/* Containers */
.text-border-white {
  width: 100%;
  background-color: white;
  border-radius: 20px;
  margin: 1% 0 1% 0;
  font-size: 5vw;
  overflow: auto;
  display: flex;
}

.content-no-background {
  width: 100%;
  overflow: visible;
  border-radius: var(--medium-container-radius);
  line-height: var(--headline-line-height);
}

.box-shadow:hover {
  opacity: 10;
  box-shadow: 0 0 15px 2px whitesmoke;
}

/* Medium devices */
@media screen and (min-width: 768px) {
  :root {
    --main-padding-top: 1%;
    --virticle-padding: 0 0 12px 0;

    --button-radius-small: 17px;
    --medium-container-radius: 20px;
    --border-radius-small: 25px;
    --image-border-radius: 20px;
    --long-button-radius: 15px;

    --half-page-container-height: 53vh;
    --half-page-container-min-height: 68vw;

    --sub-section-container-height: 10em;
    --sub-section-container-min-height: 190px;

    --learn-image-size: 10em;

    --sub-text-more: 0.8em;
    --sub-text-less: 1em;
    --button-font-size: 3.3vw;

    --tutor-button-height: 50px;
    --sub-button-border: 4px solid black;
    --footer-header-buttons: 3px solid black;
    --headline-line-height: 1.3;
    --spinner-size: 80px;
    --spinner-thickness: 80px;
  }

  #subcontent-container-top {
    order: 3;
    width: 40%;
    min-height: var(--sub-section-container-min-height);
    height: var(--sub-section-container-min-height);
    display: flex;
    padding: var(--virticle-padding);
  }

  #subcontent-container-bottom {
    order: 4;
    width: 60%;
    min-height: var(--sub-section-container-min-height);
    height: var(--sub-section-container-height);
    display: flex;
    padding: var(--virticle-padding);
    padding-left: 10px;
  }

  #half-page-container-size-to-content {
    height: auto;
    min-height: auto;
  }

  .image-container {
    padding: 43px;
  }

  .header-element-left {
    justify-content: center;
  }
}

/* Desktop/ Laptop devices */
@media screen and (min-width: 1024px) {

  :root {
    --main-padding-top: 0;
    --virticle-padding: 0 0 20px 0;

    --button-radius-small: 17px;
    --medium-container-radius: 20px;
    --border-radius-small: 25px;
    --image-border-radius: 20px;
    --long-button-radius: 15px;

    --half-page-container-height: 100vh;
    --half-page-container-min-height: 300px;

    --sub-section-container-height: 20vh;
    --sub-section-container-min-height: 100px;

    --learn-image-size: 11em;

    --sub-text-more: 1.2em;
    --sub-text-less: 1em;
    --button-font-size: 50px;

    --tutor-button-height: 10em;
    --sub-button-border: 3px solid black;
    --footer-header-buttons: 3px solid black;
    --headline-line-height: 1.2;
    --spinner-thickness: 150px;

    --padding-left-column: 22px;
  }

  .spinner {
    width: 198px;
    height: 150px;
    padding: 10px;
    margin: 49px;
    position: relative;
  }

  #header-text-container {
    width: 50%;
    min-height: 12vw;
    height: 22vh;
    padding-right: var(--padding-left-column);
  }

  #footer {
    height: 10vh;
  }

  #main-content-container {
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 430px;
    height: 70vh;
  }

  .header-element-right {
    width: 50%;
  }

  /* # Adjusts header button margins */
  .header-element-right button:first-child {
    margin-left: 0;
  }

  .header-element-right button:nth-child(3) {
    margin-right: 0;
  }

  .header-element-right button {
    margin-top: 1%;
  }

  .text-border-buy {
    padding: 22px;
  }

  #subcontent-container-top {
    order: 2;
    width: 50%;
    display: flex;
    min-height: var(--sub-section-container-height);
    height: var(--sub-section-container-min-height);
    padding: var(--virticle-padding);
    padding-right: var(--padding-left-column);
  }

  #subcontent-container-bottom {
    order: 3;
    width: 50%;
    padding-right: var(--padding-left-column);
    min-height: var(--sub-section-container-min-height);
    height: var(--sub-section-container-height);
    display: flex;
    padding-left: 0;
  }

  #half-page-container {
    width: 50%;
    order: 4;
    height: var(--half-page-container-height);
    min-height: var(--half-page-container-min-height);
    display: flex;
  }

  #half-page-container-size-to-content {
    width: 50%;
    order: 4;
    height: var(--half-page-container-height);
    min-height: var(--half-page-container-min-height);
    display: flex;
  }

  .contact-button {
    height: 50%;
  }
}

@media screen and (min-width: 1080px) {

  :root {
    --main-padding-top: 0;
    --virticle-padding: 0 0 20px 0;

    --button-radius-small: 17px;
    --medium-container-radius: 20px;
    --border-radius-small: 25px;
    --image-border-radius: 20px;
    --long-button-radius: 15px;

    --half-page-container-height: 78vh;
    --half-page-container-min-height: 610px;

    --sub-section-container-height: 20vh;
    --sub-section-container-min-height: 200px;

    --learn-image-size: 11em;

    --sub-text-more: 1.2em;
    --sub-text-less: 1em;
    --button-font-size: 50px;

    --tutor-button-height: 10em;
    --sub-button-border: 3px solid black;
    --footer-header-buttons: 3px solid black;
    --headline-line-height: 1.2;
    --spinner-thickness: 150px;

    --padding-left-column: 22px;
  }

  .spinner {
    width: 198px;
    height: 150px;
    padding: 10px;
    margin: 49px;
    position: relative;
  }

  #header-text-container {
    width: 50%;
    min-height: 12vw;
    height: 22vh;
    padding-right: var(--padding-left-column);
  }

  #footer {
    height: 10vh;
  }

  #main-content-container {
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 610px;
    height: 72vh;
  }

  .header-element-right {
    width: 50%;
  }

  /* # Adjusts header button margins */

  .header-element-right button:first-child {
    margin-left: 0;
  }

  .header-element-right button:nth-child(3) {
    margin-right: 0;
  }

  .header-element-right button {
    margin-top: 1%;
  }

  .text-border-buy {
    padding: 22px;
  }

  #subcontent-container-top {
    order: 2;
    width: 50%;
    display: flex;
    min-height: var(--sub-section-container-height);
    height: var(--sub-section-container-min-height);
    padding: var(--virticle-padding);
    padding-right: var(--padding-left-column);
  }


  #subcontent-container-bottom {
    order: 3;
    width: 50%;
    padding-right: var(--padding-left-column);
    min-height: var(--sub-section-container-min-height);
    height: var(--sub-section-container-height);
    display: flex;
    padding-left: 0;
  }

  #half-page-container {
    width: 50%;
    order: 4;
    height: var(--half-page-container-height);
    min-height: var(--half-page-container-min-height);
    display: flex;
  }

  #half-page-container-size-to-content {
    width: 50%;
    order: 4;
    height: var(--half-page-container-height);
    min-height: var(--half-page-container-min-height);
    display: flex;
  }
}