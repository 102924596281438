.container {
  width: 100%;
  height: 50vh;
}

/* Containers*/
.drumMachineContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 6px;
  background: linear-gradient(to bottom, #bcc6cc, #eee, #bcc6cc);
}

.topControlsContainer {
  background-color: palevioletred;
  width: 100%;
  padding: 3px;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.instrumentLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.transportContainer {
  padding: 3px;
  border-radius: 10px;
  display: flex;
  width: 20%;
  justify-content: start;
}

.extraFxButtonsContainer {
  padding: 3px;
  border-radius: 10px;
  display: flex;
  width: 50%;
  justify-content: space-around;

}

.drumLogo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.drumName {
  font-family: 'Rajdhani', sans-serif;
  color: black;
  font-size: 25px;
}

.drumButton {
  width: 10vw;
  max-width: 35px;
  height: 100%;
  margin-bottom: 2px;
  color: black;
  background-color: #fff;
  border-radius: 10px;
  font-size: 13px;
  border: solid black 2px;
  cursor: pointer;
}

.drumButton:disabled {
  background-color: lightcyan;
  color: black;
  font-weight: 800px;
}

.transportButton {
  width: 12vw;
  max-width: 55px;
}

.resetButton {
  border: none;
  border-radius: 10px;
  background-color: transparent;
  height: 100%;
  width: 10vw;
  max-width: 35px;
  font-size: 16px;
  color: white;
}

.resetButton:hover {
  background-color: red;
}


/* Step Sequencer */
.instrument {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  font-size: 10px;
}

.step {
  width: 15px;
  height: 10px;
  margin-bottom: 2px;
  background-color: #fff;
  border-radius: 0;
  border: solid black 2px;
  cursor: pointer;
}

@keyframes pulse {
  0% {
    background-color: transparent;
  }

  50% {
    background-color: yellow;
  }

  100% {
    background-color:

      transparent;
  }
}

/* Buttons */
.abButtons {
  font-size: 10px;
}

.muted {
  animation: pulse 1s infinite;
}

.unmuted {
  background-color: lightgreen;
}

.blue {
  border-color: black;
}

.black {
  background-color: black;
  border-color: black;
}

.active {
  background-color: green;
}

.current {
  background-color: red;
}

/* Playing with buttons */
.front {
  will-change: transform;
  transition: transform 150ms;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.pushable:hover .front {
  transform: translateY(2px);
}

.pushable:active .front,
.pushableActive {
  transform: translateY(1px);
}


.pushable {
  width: 10vw;
  max-width: 35px;
  height: 100%;
  background: black;
  border: none;
  border-radius: 10px;
  padding: 0;
  cursor: pointer;
}

.front {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 10vw;
  /* sets button width */
  max-width: 35px;
  height: 100%;
  margin-bottom: 2px;
  background-color: #fff;
  border-radius: 10px;
  font-size: 13px;
  border: solid black 2px;
  transform: translateY(2px);
}

/* early - middle sizes */
@media screen and (min-width: 568px) {
  .drumMachineContainer {
    padding: 10px;
    border: solid black 2px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
  }
}

/* mid-size */
@media screen and (min-width: 768px) {
  .drumMachineContainer {
    width: 80%;
    max-width: 600px;
  }
}

/* large screen sizes */
@media screen and (min-width: 1080px) {
  .drumMachineContainer {
    height: auto;
  }
}