.image-container {
  position: relative;
}

.image-fade {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  animation: fade 0.8s ease-in-out forwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.image-fade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.image-fade.show {
  opacity: 1;
}

.border {
  width: 100%;
  background-color: #d3d3d3 !important;
  border-radius: 20px;
  margin: 1% 0 1% 0;
  font-size: 20px;
  overflow: auto;
  height: 70vh;
}




